import { Button } from '@ufx-ui/core'
import cx from 'classnames'
import _get from 'lodash/get'
import React, { useEffect, useContext, useState } from 'react'
import Tooltip from 'react-simple-tooltip'

import AppContext from '../../context/AppContext'
import useTicker, { LAST_PRICE } from '../../hooks/useTicker'
import useWindowSize from '../../hooks/useWindowSize'
import copy from '../../images/copy.svg'
import CloseCircle from '../../images/icons/close-circle.svg'
import success from '../../images/success.svg'
import ws from '../../ws'
import CharityText from '../CharityText'
import QrCode from '../QrCode'

const SAT_BTC_SCALE = 100000000
const BTC_DECIMALS = 8
const SATS_UNIT = 'Sats'
const BTC_UNIT = 'BTC'

export default function Contribute({
  id,
  avatar,
  goal,
  duration,
  charity,
  onClose,
}) {
  const [amount, setAmount] = useState(5000)
  const [unit, setUnit] = useState(SATS_UNIT)
  const [showSuccess, setShowSuccess] = useState(false)
  const [copyType, setCopyType] = useState('')
  const {
    bitcoinAddress,
    setBitcoinAddress,
    // lightningAddress = '3WSfhlkj4kb5kgb5kb56kgada66k',
    lightningAddress,
    setLightningAddress,
    blocked,
    setBlocked,
    invoicePaid,
    setInvoicePaid,
  } = useContext(AppContext)
  const { isMD: isMobile } = useWindowSize()
  const ticker = useTicker()

  useEffect(() => {
    if (invoicePaid) {
      onClose()
    }
  }, [invoicePaid, onClose])

  const onModalClose = () => {
    setLightningAddress('')
    setBitcoinAddress('')
    onClose()
  }

  const showQRCode = lightningAddress || bitcoinAddress

  useEffect(
    () => () => {
      setLightningAddress('')
      setBitcoinAddress('')
      setBlocked(false)
      setInvoicePaid(false)
    },
    [setBitcoinAddress, setBlocked, setLightningAddress, setInvoicePaid]
  )

  const handlePay = e => {
    e.stopPropagation()
    ws.getInstance(socket => {
      socket.send(
        JSON.stringify({
          method: 'pay',
          params: {
            id,
            amount: unit === SATS_UNIT ? amount : amount * SAT_BTC_SCALE,
          },
        })
      )
    })
  }

  const onChangeUnit = e => {
    e.stopPropagation()
    e.preventDefault()

    const newUnit = unit === SATS_UNIT ? BTC_UNIT : SATS_UNIT
    if (newUnit === SATS_UNIT) {
      setAmount(amount * SAT_BTC_SCALE)
    } else {
      setAmount(amount / SAT_BTC_SCALE)
    }

    setUnit(newUnit)
  }

  const handleCopy = async (payload, type = 'btc') => {
    await navigator.clipboard.writeText(payload)
    setShowSuccess(true)
    setCopyType(type)
    setTimeout(() => setShowSuccess(false), 1000)
  }

  const setAmountForUnit = value => {
    if (unit === SATS_UNIT) {
      setAmount(value)
    } else {
      setAmount(value / SAT_BTC_SCALE)
    }
  }

  const handlePay1PercClick = e => {
    e.stopPropagation()

    const satsAmount = Math.ceil(goal / 100)
    setAmountForUnit(satsAmount)
  }

  const handlePay1MinuteClick = e => {
    e.stopPropagation()

    const satsAmount = duration !== 0 ? Math.ceil((goal * 60) / duration) : 0
    setAmountForUnit(satsAmount)
  }

  const handlePay1USDClick = e => {
    e.stopPropagation()

    const BTCUSDRate = _get(ticker, LAST_PRICE)
    const btcAmount = (1 / BTCUSDRate).toFixed(BTC_DECIMALS)

    if (unit === SATS_UNIT) {
      setAmount(btcAmount * SAT_BTC_SCALE)
    } else {
      setAmount(btcAmount)
    }
  }

  if (blocked) {
    return (
      <div className='contribute__blocked'>
        <div className='contribute__bold'>
          This feature is not available in your area.
        </div>
        <div className='contribute__light'>
          Please enjoy listening to any content that is already unlocked!
        </div>
      </div>
    )
  }

  const closeIcon = (
    <Button minimal onClick={onModalClose}>
      <img src={CloseCircle} alt='close-icon' />
    </Button>
  )

  return (
    <div className='contribute'>
      {isMobile && (
        <div>
          <br />
          <div className='close-icon-wrapper'>
            <div>
              <p className='contribute__name'>Unlock this episode</p>
              {!showQRCode && (
                <p className='contribute__tips'>
                  Every payment proportionally unlocks audio for everyone.
                </p>
              )}
            </div>

            {closeIcon}
          </div>
        </div>
      )}
      <div
        style={{ display: !showQRCode ? 'none' : 'flex' }}
        className={cx('contribute__person-img', {
          'qr-code': showQRCode,
        })}
      >
        <QrCode
          lightning={lightningAddress}
          bitcoin={bitcoinAddress}
          defaultSrc={avatar}
          blocked={blocked}
        />
      </div>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className='contribute__content' onClick={e => e.stopPropagation()}>
        <div>
          {!isMobile && (
            <p className='contribute__name'>Help unlock this episode!</p>
          )}
          {!isMobile && (
            <p className='contribute__tips'>
              Every payment proportionally unlocks audio for everyone.
            </p>
          )}
        </div>

        <div
          className={cx('contribute__addresses', 'show-hide-effect', {
            hide: !showQRCode,
          })}
        >
          <div className='contribute__address-line'>
            <img src='/btc.png' alt='btc' />
            <input
              disabled
              className='contribute__address'
              value={bitcoinAddress}
            />
            <Tooltip
              content={showSuccess ? 'Copied' : 'Copy'}
              fontSize='12px'
              padding={6}
              radius={3}
            >
              <img
                role='presentation'
                className='copy-icon'
                onClick={e => {
                  e.stopPropagation()
                  handleCopy(bitcoinAddress, 'btc')
                }}
                src={showSuccess && copyType === 'btc' ? success : copy}
                alt='copy'
              />
            </Tooltip>
          </div>
          <div className='contribute__address-line'>
            <img src='/lightening.png' alt='lightning' />
            <input
              disabled
              className='contribute__address'
              value={lightningAddress}
            />
            <Tooltip
              content={showSuccess ? 'Copied' : 'Copy'}
              fontSize='12px'
              padding={6}
              radius={3}
            >
              <img
                data-tip={showSuccess ? 'Copied' : 'Copy address'}
                role='presentation'
                onClick={e => {
                  e.stopPropagation()
                  handleCopy(lightningAddress, 'lightning')
                }}
                src={showSuccess && copyType === 'lightning' ? success : copy}
                alt='copy'
                className='copy-icon'
              />
            </Tooltip>
          </div>
          <br />
          {!isMobile && <CharityText charity={charity} />}
        </div>
        <div className={cx('show-hide-effect', { hide: showQRCode })}>
          <div className='contribute__pay-btns'>
            <div className='pay-btn-container'>
              <Button onClick={handlePay1USDClick}>$1.00</Button>
              <Button onClick={handlePay1MinuteClick}>1 minute</Button>
              <Button onClick={handlePay1PercClick}>1%</Button>
            </div>
            <div className='contribute__input-wrapper'>
              <div className='contribute__input'>
                <div className='icon'>
                  {unit === SATS_UNIT ? (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 36'>
                      <path
                        fill='#FFAC33'
                        d='M32.938 15.651A1.002 1.002 0 0032 15H19.925L26.89 1.458A.999.999 0 0026 0a1 1 0 00-.653.243L18 6.588 3.347 19.243A1 1 0 004 21h12.075L9.11 34.542A.999.999 0 0010 36a1 1 0 00.653-.243L18 29.412l14.653-12.655a1 1 0 00.285-1.106z'
                      />
                    </svg>
                  ) : (
                    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'>
                      <path
                        fill='#f7931a'
                        d='M63.04 39.741c-4.275 17.143-21.638 27.576-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z'
                      />
                      <path
                        fill='#FFF'
                        d='M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.51-.875-1.4 5.616c-.924-.23-1.872-.447-2.814-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.019 4.57 1.139c.85.213 1.682.436 2.502.646l-1.453 5.834 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726l-1.434 5.745 3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.384-4.739 1.527-4.36-.076-6.875-3.226-8.515 2.294-.529 4.022-2.038 4.483-5.155zM38.086 38.69c-1.085 4.36-8.426 2.003-10.806 1.412l1.928-7.729c2.38.594 10.012 1.77 8.878 6.317zm1.086-11.312c-.99 3.966-7.1 1.951-9.082 1.457l1.748-7.01c1.982.494 8.365 1.416 7.334 5.553z'
                      />
                    </svg>
                  )}
                </div>
                <input
                  className='amount'
                  type='number'
                  value={amount}
                  min='0'
                  step={unit === SATS_UNIT ? '1' : '0.001'}
                  onChange={e => setAmount(Number(e.target.value))}
                />
                <button class='unit' onClick={onChangeUnit}>
                  {unit}
                </button>
              </div>

              {!isMobile && (
                <Button
                  disabled={ws.isConnecting()}
                  onClick={handlePay}
                  intent='primary'
                >
                  {ws.isConnecting() ? '...' : 'Pay'}
                </Button>
              )}
            </div>
          </div>

          {isMobile && (
            <>
              <br />
              <CharityText charity={charity} />
            </>
          )}
        </div>
      </div>

      {!isMobile && <div className='close-icon-wrapper'>{closeIcon} </div>}
      {isMobile && !showQRCode && (
        <Button
          disabled={ws.isConnecting()}
          onClick={handlePay}
          intent='primary'
        >
          {ws.isConnecting() ? '...' : 'Contribute'}
        </Button>
      )}
    </div>
  )
}
